import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import FormInput from './FormInput'; // Компонент для инпутов с валидацией
import { getAllMasters, getAllClusters } from '../api'; // Предположим, что есть API для получения всех мастеров
import './CreateOrEditFactoryModal.css';
import Toast from './Toast';

const CreateOrEditFactoryModal = ({ factory, onSave, onClose, validationErrors }) => {
    const [name, setName] = useState(factory ? factory.name : '');
    const [selectedMaster, setSelectedMaster] = useState(factory && factory.master ? { value: factory.master.id, label: factory.master.fio } : null);
    const [masters, setMasters] = useState([]);
    const [isDeleted, setIsDeleted] = useState(factory ? factory.deleted : false); // Добавляем состояние для "удалён"
    const [clusterId, setClusterId] = useState(factory?.cluster?.id || ''); // поле для выбора кластера
    const [clusters, setClusters] = useState([]);
    const [toastMessage, setToastMessage] = useState(null);
    const [toastType, setToastType] = useState(''); // success или error

    useEffect(() => {
        loadClusters();
    }, []);

    const loadClusters = () => {
            getAllClusters().then(response => {
                setClusters(response.data);
            }).catch(error => {
                console.error('Error loading clusters', error);
            });
        };

    useEffect(() => {
        // Загружаем список мастеров
        getAllMasters().then(response => {
            const masterOptions = response.data.map(master => ({
                value: master.id,
                label: master.fio
            }));
            setMasters(masterOptions);
        });
    }, []);

    const handleSave = () => {
        if (!name?.trim()) {
            setToastMessage('Название завода не может быть пустым');
            setToastType('error');
            return;
        }

        if (!clusterId) {
            setToastMessage('Необходимо выбрать кластер');
            setToastType('error');
            return;
        }

        const updatedFactory = {
            id: factory?.id,
            name,
            deleted: isDeleted,
            clusterId: clusterId,
            masterId: selectedMaster ? selectedMaster.value : null,
        };

        onSave(updatedFactory);
    };


    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>{factory ? 'Редактировать завод' : 'Создать новый завод'}</h2>

                <FormInput
                    label="Название завода"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    error={validationErrors?.name}
                    placeholder="Введите название завода"
                    className="create-edit-factory__form-group"
                />

                <div className="create-edit-factory__form-group">
                    <label>Курирующий мастер:</label>
                    <Select
                        value={selectedMaster}
                        options={masters}
                        onChange={setSelectedMaster}
                        placeholder="Выберите мастера"
                        isClearable
                    />
                </div>

                <div className="create-edit-factory__form-group">
                    <label>Кластер</label>

                    <select
                        value={clusterId}
                        onChange={(e) => setClusterId(e.target.value)}>
                        <option value="">Выберите кластер</option>
                                    {clusters.map(cluster => (
                                        <option key={cluster.id} value={cluster.id}>{cluster.name}</option>
                                    ))}
                    </select>

                    {validationErrors.clusterId && (<div className="error">{validationErrors.clusterId}</div>)}
                </div>

                {factory && factory.deleted && (
                    <div className="deleted_div">
                            <input
                                className="deletedInput"
                                type="checkbox"
                                checked={isDeleted}
                                onChange={(e) => setIsDeleted(e.target.checked)}
                                id="deletedCheckbox"
                            />
                            <label htmlFor="deletedCheckbox" className="deletedLabel">Удалён</label>
                    </div>
                )}

                <div className="create-edit-factory__modal-actions">
                    <button onClick={handleSave} className="create-edit-factory__btn-save">Сохранить</button>
                    &nbsp;
                    <button onClick={onClose} className="create-edit-factory__btn-cancel">Отменить</button>
                </div>
            </div>

            {toastMessage && (
                            <Toast
                                message={toastMessage}
                                type={toastType}
                                onClose={() => setToastMessage(null)}
                            />
                        )}
        </div>
    );
};

export default CreateOrEditFactoryModal;
