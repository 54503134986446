import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import MasterReportForm from './components/master/MasterReportForm';
import ManagerLogin from './components/ManagerLogin';
import FactoriesPage from './components/FactoriesPage';
import MastersPage from './components/MastersPage';
import DailyReportsPage from './components/DailyReportsPage';
import MonthlyReportsPage from './components/MonthlyReportsPage';
import ManagerMenu from './components/ManagerMenu'; // Подключаем главное меню
import AnalyticsPage from './components/AnalyticsPage';  // Импортируем страницу Аналитика



function App() {
    const location = useLocation(); // Получаем текущий путь
    const isMasterRoute = location.pathname.startsWith('/master'); // Проверяем, начинается ли путь с /master

    return (
            <div>
                {!isMasterRoute && <ManagerMenu />} {/* Меню будет скрыто для маршрутов /master/... */}
                <Routes>
                    <Route path="/" element={<ManagerLogin />} />
                    <Route path="/manager/factories" element={<FactoriesPage />} />
                    <Route path="/manager/masters" element={<MastersPage />} />
                    <Route path="/manager/daily-reports" element={<DailyReportsPage />} />
                    <Route path="/manager/monthly-reports" element={<MonthlyReportsPage />} />
                    <Route path="/master/:uniqueLink" element={<MasterReportForm />} />
                    <Route path="/manager/login" element={<ManagerLogin />} />
                    <Route path="/manager/analytics" element={<AnalyticsPage />} />
                </Routes>
            </div>
    );
}

function AppWrapper() {
    return (
        <Router>
            <App />
        </Router>
    );
}

export default AppWrapper;
