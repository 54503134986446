import React, { useState, useEffect } from 'react';
import { getAllClusters, createCluster, updateFactoryOrderInCluster, updateCluster, deleteCluster, updateClusterOrder, createOrUpdateFactory, deleteFactory } from '../api';
import CreateOrEditFactoryModal from './CreateOrEditFactoryModal';
import './FactoriesPage.css';
import Toast from './Toast';
import { colorOptionsFind, colorOptions } from './ColorOptions';



const FactoriesPage = () => {
    const [clusters, setClusters] = useState([]);
    const [expandedClusters, setExpandedClusters] = useState({});
    const [toastMessage, setToastMessage] = useState(null);
    const [toastType, setToastType] = useState('');
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [newClusterName, setNewClusterName] = useState('');
    const [isEditModalOpen, setIsEditModalOpen] = useState(null);
    const [isModifyFactoryModalOpen, setIsModifyFactoryModalOpen] = useState(null);
    const [selectedColor, setSelectedColor] = useState('');

    useEffect(() => {
        loadClusters();
    }, []);

    const loadClusters = () => {
        getAllClusters().then(response => {
            setClusters(response.data);
            setExpandedClusters(prevState => {
                const updatedExpandedState = { ...prevState };
                response.data.forEach(cluster => {
                    if (!(cluster.id in updatedExpandedState)) {
                        updatedExpandedState[cluster.id] = false;
                    }
                });
                return updatedExpandedState;
            });
        }).catch(error => {
            console.error('Error loading clusters', error);
            setToastMessage(error.response?.data?.message || 'Ошибка загрузки кластеров');
            setToastType('error');
        });
    };

    const toggleCluster = (clusterId) => {
        setExpandedClusters(prevState => ({
            ...prevState,
            [clusterId]: !prevState[clusterId]
        }));
    };

    const toggleAllClusters = (expandAll) => {
        const updatedExpandedClusters = {};
        clusters.forEach(cluster => {
            updatedExpandedClusters[cluster.id] = expandAll;
        });
        setExpandedClusters(updatedExpandedClusters);
    };

    const moveFactory = (clusterId, factoryIndex, direction) => {
        const clusterIndex = clusters.findIndex(cluster => cluster.id === clusterId);
        if (clusterIndex === -1) return;

        const factories = Array.from(clusters[clusterIndex].factories);
        const newIndex = factoryIndex + direction;

        if (newIndex < 0 || newIndex >= factories.length) return;

        const [movedFactory] = factories.splice(factoryIndex, 1);
        factories.splice(newIndex, 0, movedFactory);

        const updatedClusters = [...clusters];
        updatedClusters[clusterIndex].factories = factories;
        setClusters(updatedClusters);

        const factoryIds = factories.map(factory => factory.id);
        updateFactoryOrderInCluster(clusterId, factoryIds)
            .then(() => {
                loadClusters();
                setToastMessage('Порядок заводов успешно обновлен');
                setToastType('success');
            })
            .catch(error => {
                console.error('Ошибка при обновлении порядка заводов', error);
                setToastMessage(error.response?.data?.message || 'Ошибка при обновлении порядка заводов');
                setToastType('error');
            });
    };

    const moveCluster = (clusterIndex, direction) => {
        const newIndex = clusterIndex + direction;
        if (newIndex < 0 || newIndex >= clusters.length) return;

        const updatedClusters = Array.from(clusters);
        const [movedCluster] = updatedClusters.splice(clusterIndex, 1);
        updatedClusters.splice(newIndex, 0, movedCluster);

        setClusters(updatedClusters);

        // Preserve the expanded state of clusters
        setExpandedClusters(prevState => {
            const updatedExpandedState = { ...prevState };
            const movedClusterId = movedCluster.id;
            const wasExpanded = prevState[movedClusterId];
            updatedExpandedState[movedClusterId] = wasExpanded;
            return updatedExpandedState;
        });

        const clusterIds = updatedClusters.map(cluster => cluster.id);
        updateClusterOrder(clusterIds)
            .then(() => {
                setToastMessage('Порядок кластеров успешно обновлен');
                setToastType('success');
            })
            .catch(error => {
                console.error('Ошибка при обновлении порядка кластеров', error);
                setToastMessage(error.response?.data?.message || 'Ошибка при обновлении порядка кластеров');
                setToastType('error');
            });
    };

    const handleCreateCluster = (clusterName) => {
        if (!clusterName?.trim()) {
            setToastMessage('Название кластера не может быть пустым');
            setToastType('error');
            return;
        }

        createCluster({ name: clusterName, color: selectedColor })
            .then(() => {
                setToastMessage('Кластер успешно добавлен');
                setToastType('success');
                loadClusters();
            })
            .catch(error => {
                console.error('Ошибка при добавлении кластера', error);
                setToastMessage(error.response?.data?.message || 'Ошибка при добавлении кластера');
                setToastType('error');
            });
    };

    const handleUpdateCluster = (clusterId, newName, newColor) => {

        if (!newName?.trim()) {
            setToastMessage('Название кластера не может быть пустым');
            setToastType('error');
            return;
        }

        updateCluster(clusterId, { name: newName, color: newColor })
            .then(() => {
                setToastMessage('Кластер успешно обновлен');
                setToastType('success');
                loadClusters();
            })
            .catch(error => {
                console.error('Ошибка при обновлении кластера', error);
                setToastMessage(error.response?.data?.message || 'Ошибка при обновлении кластера');
                setToastType('error');
            });
    };

    const handleDeleteCluster = (clusterId) => {
        deleteCluster(clusterId)
            .then(() => {
                setToastMessage('Кластер успешно удален');
                setToastType('success');
                loadClusters();
            })
            .catch(error => {
                console.error('Ошибка при удалении кластера', error);
                setToastMessage(error.response?.data?.message || 'Ошибка при удалении кластера');
                setToastType('error');
            });
    };

    const closeEditModal = () => {
        setIsEditModalOpen(null);
    };

    const closeCreateFactoryModal = () => {
        setIsModifyFactoryModalOpen(null);
    };

    const handleCreateOrUpdateFactory = (theFactory) => {
        createOrUpdateFactory(theFactory)
            .then(() => {
                setToastMessage('Завод успешно создан');
                setToastType('success');
                loadClusters();
            })
            .catch(error => {
                console.error('Ошибка при создании завода:', error.response);
                setToastMessage(error?.response?.data?.message);
                setToastType('error');
            });
    };

    return (
        <div className="factories-page__container">
            <div className="factories-page__cluster-controls" style={{ display: 'flex', alignItems: 'center' }}>
                <button onClick={() => setIsCreateModalOpen(true)} style={{ marginRight: 'auto' }}>Создать кластер</button>
                <button className="btn_clsp" onClick={() => toggleAllClusters(true)} style={{ marginLeft: '10px' }}>Раскрыть все</button>
                <button className="btn_clsp" onClick={() => toggleAllClusters(false)} style={{ marginLeft: '10px' }}>Свернуть все</button>
            </div>

            {isCreateModalOpen && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <h2>Создать новый кластер</h2>
                        <input
                            type="text"
                            value={newClusterName}
                            onChange={(e) => setNewClusterName(e.target.value)}
                            placeholder="Название нового кластера"
                            className="modal-input"
                        />
                        <div className="color-selection color-selection--two-rows">
                            {colorOptions.map((color) => (
                            <>
                                <div
                                    key={color.number}
                                    className={`color-option ${selectedColor === color.number ? 'selected' : ''}`}
                                    style={{ backgroundColor: color.hex }}
                                    onClick={() => setSelectedColor(color.number)}
                                />
                            </>
                            ))}
                        </div>
                        <div className="modal-actions">
                            <button className="modal-create-button" onClick={() => {
                                handleCreateCluster(newClusterName);
                                setNewClusterName('');
                                setIsCreateModalOpen(false);
                            }}>Создать</button>
                            <button className="modal-cancel-button" onClick={() => setIsCreateModalOpen(false)}>Отмена</button>
                        </div>
                    </div>
                </div>
            )}

            {isEditModalOpen && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <h2>Редактировать кластер</h2>
                        <input
                            type="text"
                            defaultValue={isEditModalOpen.name}
                            onChange={(e) => setIsEditModalOpen(prev => ({ ...prev, name: e.target.value }))}
                            placeholder="Новое название кластера"
                            className="modal-input"
                        />
                        <div className="color-selection color-selection--two-rows">
                            {colorOptions.map((color) => (
                                <div
                                    key={color.number}
                                    className={`color-option ${isEditModalOpen.color === color.number ? 'selected' : ''}`}
                                    style={{ backgroundColor: color.hex }}
                                    onClick={() => setIsEditModalOpen(prev => ({ ...prev, color: color.number }))}
                                />
                            ))}
                        </div>
                        <div className="modal-actions">
                            <button className="modal-create-button" onClick={() => {
                                handleUpdateCluster(isEditModalOpen.id, isEditModalOpen.name, isEditModalOpen.color);
                                closeEditModal();
                            }}>Сохранить</button>
                            <button className="modal-cancel-button" onClick={() => closeEditModal()}>Отмена</button>
                        </div>
                    </div>
                </div>
            )}

            {isModifyFactoryModalOpen && (
                <CreateOrEditFactoryModal
                    factory={{ ...isModifyFactoryModalOpen }}
                    onSave={(newFactory) => {
                        handleCreateOrUpdateFactory(newFactory);
                        closeCreateFactoryModal();
                    }}
                    onClose={closeCreateFactoryModal}
                    validationErrors={{}}
                />
            )}

            {clusters.map((cluster, index) => (
                <div key={cluster.id} className="factories-page__cluster" style={{ borderLeft: `10px solid ${colorOptionsFind(cluster.color)}` }}>
                    <h3 className="factories-page__cluster-header">
                        <span onClick={() => toggleCluster(cluster.id)} className="factories-page__cluster-header-title">
                            {expandedClusters[cluster.id] ? '▼' : '▶'} {cluster.name} ({cluster.factories.length})
                        </span>

                        {cluster.id && (
                            <div className="factories-page__cluster-header-buttons">
                                <button onClick={() => setIsEditModalOpen({ id: cluster.id, name: cluster.name, color: cluster.color })} className="factories-page__edit-cluster-button">
                                    Редактировать
                                </button>
                                <button onClick={() => handleDeleteCluster(cluster.id)} className="factories-page__delete-cluster-button">
                                    Удалить
                                </button>
                                <button onClick={() => moveCluster(index, -1)} disabled={index === 0} className="factories-page__move-cluster-button">
                                    ↑
                                </button>
                                <button onClick={() => moveCluster(index, 1)} disabled={index === clusters.length - 1} className="factories-page__move-cluster-button">
                                    ↓
                                </button>
                            </div>
                        )}
                    </h3>
                    {expandedClusters[cluster.id] && (
                        <div className="factories-page__factories-list">
                            <table className="factories-page__factories-table">
                                <thead>
                                    <tr>
                                        <th>Название завода</th>
                                        <th>Мастер</th>
                                        <th>Управление</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {cluster.factories.sort((a, b) => a.deleted ? 0 : a.orderIndex - b.orderIndex).map((factory, index) => (
                                        <tr key={factory.id} className="factories-page__factory-row">
                                            <td style={{ textDecoration: factory.deleted ? 'line-through' : 'none' }}>{factory.name}</td>
                                            <td>{factory.master ? factory.master.fio : '-'}</td>
                                            <td className="factories-page__buttons">
                                                <button
                                                    onClick={() => moveFactory(cluster.id, index, -1)}
                                                    disabled={index === 0}
                                                >
                                                    ↑
                                                </button>
                                                <button
                                                    onClick={() => moveFactory(cluster.id, index, 1)}
                                                    disabled={index === cluster.factories.length - 1}
                                                >
                                                    ↓
                                                </button>
                                                <button className="factories-page__edit-button"
                                                    onClick={() => {
                                                        return setIsModifyFactoryModalOpen({ ...factory, cluster: cluster });
                                                    }}>Редактировать</button>

                                                {!factory.deleted && (
                                                <button className="factories-page__delete-button"
                                                    onClick={() => {
                                                        deleteFactory(factory.id)
                                                            .then(() => {
                                                                loadClusters(); // Обновление списка заводов после удаления
                                                                setToastMessage('Завод успешно удален');
                                                                setToastType('success');
                                                            })
                                                            .catch((error) => {
                                                                console.error('Ошибка при удалении завода:', error);
                                                                setToastMessage(error.response?.data?.message || 'Ошибка при удалении завода');
                                                                setToastType('error');
                                                            });
                                                    }}>X</button>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="factories-page__create-factory-placeholder">
                                <button className="create-factory-button" onClick={() => setIsModifyFactoryModalOpen({"cluster": cluster})}>Создать завод</button>
                            </div>
                        </div>
                    )}
                </div>
            ))}

            {toastMessage && (
                <Toast
                    message={toastMessage}
                    type={toastType}
                    onClose={() => setToastMessage(null)}
                />
            )}
        </div>
    );
};

export default FactoriesPage;

