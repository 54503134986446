import React, { useState, useEffect } from 'react';
import { getAllMasters, createMaster, updateMaster, getAllFactories, deleteMaster } from '../api';
import './MastersPage.css';
import CreateOrEditMasterModal from './CreateOrEditMasterModal';
import Toast from './Toast';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

const MastersPage = () => {
    const [masters, setMasters] = useState([]);
    const [factories, setFactories] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingMaster, setEditingMaster] = useState(null);
    const [validationErrors, setValidationErrors] = useState({}); // Добавляем состояние для ошибок валидации
    const [toastMessage, setToastMessage] = useState(null);
    const [toastType, setToastType] = useState(''); // success или error

    useEffect(() => {
        loadMasters();
        loadFactories();
    }, []);

    const loadMasters = () => {
        getAllMasters().then(response => {
            setMasters(response.data);
        }).catch(error => {
            console.error('Error loading masters', error);
            setToastMessage(error?.response?.data?.error || 'Ошибка загрузки мастеров');
            setToastType('error');
        });
    };

    const loadFactories = () => {
        getAllFactories().then(response => {
            setFactories(response.data);
        }).catch(error => {
            console.error('Error loading factories', error);
            setToastMessage(error?.response?.data?.error || 'Ошибка загрузки заводов');
            setToastType('error');
        });
    };



    const handleSaveMaster = (master) => {
        const masterData = {
            fio: master.fio,
            phone: master.phone,
            uniqueLink: master.uniqueLink,
            factoryIds: master.factories
        };

        if (master.id) {
            updateMaster(master.id, masterData)
                .then(() => {
                    setIsModalOpen(false);
                    loadMasters();
                    setToastMessage('Мастер успешно обновлен');
                    setToastType('success');
                })
                .catch(error => {
                    if (error.response?.data?.errors) {
                        const errors = error.response.data.errors;
                        setValidationErrors(errors);
                    } else {
                        //setToastMessage(error.response?.data?.error || 'Ошибка при обновлении мастера');
                        setToastMessage(error.response?.data?.message || 'Ошибка при обновлении мастера');
                        setToastType('error');
                    }
                });
        } else {
            createMaster(masterData)
                .then(() => {
                    setIsModalOpen(false);
                    loadMasters();
                })
                .catch(error => {
                    if (error.response?.data?.errors) {
                        const errors = error.response.data.errors;
                        setValidationErrors(errors);
                    } else {
                        setToastMessage(error.response?.data?.message || 'Ошибка при создании мастера');
                        setToastType('error');
                    }
                });
        }
    };




    const openEditModal = (master) => {
        setEditingMaster(master);
        setValidationErrors({}); // Очищаем ошибки при открытии нового модального окна
        setIsModalOpen(true);
    };

    const handleDeleteMaster = (masterId) => {
        if (window.confirm("Вы уверены, что хотите удалить этого мастера?")) {
            deleteMaster(masterId).then(() => {
                loadMasters();
            }).catch(error => {
                setToastMessage(error.response?.data?.message || 'Ошибка при удалении мастера');
                setToastType('error');
                console.error('Error deleting master', error);
            });
        }
    };

    return (
        <div className="masters_page-container">

            <button onClick={() => { setEditingMaster(null); setIsModalOpen(true); }} className="btn-create-master">
                Создать мастера
            </button>

            <div className="masters-list">
                <h3>Список мастеров</h3>
                <table className="master-table">
                    <thead>
                        <tr>
                            <th>ФИО</th>
                            <th>Телефон</th>
                            <th>Заводы</th>
                            <th>Ссылка</th>
                            <th>Действия</th>
                        </tr>
                    </thead>
                    <tbody>
                        {masters && Array.isArray(masters) && masters.length > 0 ? (
                            masters.map(master => (
                                <tr key={master.id}>
                                    <td>{master.fio}</td>
                                    <td className="nowrap">{master.phone}</td>
                                    <td>
                                                        {master.factories && master.factories.length > 0
                                                            ? master.factories.map((factory, index) => (
                                                                <span key={factory.id}>
                                                                    {factory.deleted ? <s>{factory.name}</s> : factory.name}
                                                                    {index < master.factories.length - 1 && ', '}
                                                                </span>
                                                            ))
                                                            : '-'}
                                                    </td>
                                    <td className="nowrap">
                                        {master.uniqueLink && (
                                            <>
                                                <button
                                                    onClick={() => navigator.clipboard.writeText(`${window.location.origin}/master/${master.uniqueLink}`) && alert(`Скопировано: ${window.location.origin}/master/${master.uniqueLink}`)}
                                                    style={{ marginLeft: '10px', cursor: 'pointer', background: 'none', border: 'none' }}
                                                    title="Скопировать ссылку"
                                                >
                                                    <FontAwesomeIcon icon={faCopy} style={{ color: '#bbb' }} />

                                                </button>
                                                <a href={`/master/${master.uniqueLink}`} target="_blank" rel="noopener noreferrer">
                                                                                                    {master.uniqueLink}
                                                                                                </a>
                                            </>
                                        )}
                                    </td>


                                    <td>
                                        <button onClick={() => openEditModal(master)}>Редактировать</button>
                                        <button onClick={() => handleDeleteMaster(master.id)} className="btn-delete">
                                            Удалить
                                        </button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5">Данные не загружены или отсутствуют</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            {isModalOpen && (
                <CreateOrEditMasterModal
                    master={editingMaster}
                    factories={factories}
                    onSave={handleSaveMaster}
                    onClose={() => setIsModalOpen(false)}
                    validationErrors={validationErrors} // Передаем ошибки в модальное окно
                />
            )}

            {toastMessage && (
                <Toast
                    message={toastMessage}
                    type={toastType}
                    onClose={() => setToastMessage(null)}
                />
            )}
        </div>
    );
};

export default MastersPage;
