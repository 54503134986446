import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { getAvailableFactoriesForMaster } from '../api'; // API для получения доступных заводов
import FormInput from './FormInput'; // Компонент для инпутов с валидацией
import './CreateOrEditMasterModal.css'; // Стили
import '@fortawesome/fontawesome-free/css/all.min.css'; // Подключаем стили FontAwesome
import Toast from './Toast';
const { v4: uuidv4 } = require('uuid');


// Функция для генерации уникального идентификатора
const generateUniqueLink = () => {
    //return Math.random().toString(36).substr(2, 9);
    return uuidv4();
};

const CreateOrEditMasterModal = ({ master, onSave, onClose, validationErrors }) => {
    const [fio, setFio] = useState(master ? master.fio : '');
    const [phone, setPhone] = useState(master ? master.phone : '');
    const [uniqueLink, setUniqueLink] = useState(master ? master.uniqueLink : generateUniqueLink());
    const [selectedFactories, setSelectedFactories] = useState([]);
    const [availableFactories, setAvailableFactories] = useState([]);
    const [toastMessage, setToastMessage] = useState(null);
    const [toastType, setToastType] = useState(''); // success или error

    useEffect(() => {
        getAvailableFactoriesForMaster(master ? master.id : 0).then(response => {
            const factoryOptions = response.data.map(factory => ({
                value: factory.id,
                label: factory.name
            }));
            setAvailableFactories(factoryOptions);

            if (master && master.factories) {
                const initialSelectedFactories = master.factories.map(factory => ({
                    value: factory.id,
                    label: factory.name
                }));
                setSelectedFactories(initialSelectedFactories);
            }
        }).catch(e => {
            console.log(e);
            setToastMessage('Ошибка при загрузке заводов: ' + e?.message);
            setToastType('error');
        });

    }, [master]);

    const handleSave = () => {
        const updatedMaster = {
            ...master,
            fio,
            phone,
            uniqueLink,
            factories: selectedFactories.map(factory => factory.value) // Массив с ID выбранных заводов
        };
        onSave(updatedMaster);
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>{master ? 'Редактировать мастера' : 'Создать нового мастера'}</h2>

                <FormInput
                    label="ФИО"
                    type="text"
                    value={fio}
                    onChange={(e) => setFio(e.target.value)}
                    error={validationErrors?.fio}
                    placeholder="Введите ФИО"
                    className="create-edit-master__form-group"
                />

                <FormInput
                    label="Телефон"
                    type="text"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    error={validationErrors?.phone}
                    placeholder="Введите телефон"
                    className="create-edit-master__form-group"
                />

                <div className="create-edit-master__form-group unique-link-group">
                    <label>Код для ссылки</label>
                    <div className="unique-link-wrapper">
                        <input
                            type="text"
                            value={uniqueLink}
                            onChange={(e) => setUniqueLink(e.target.value)}
                            className="create-edit-master__input-field"
                            readOnly
                        />
                        <i
                            className="fas fa-sync-alt unique-link-icon"
                            onClick={() => setUniqueLink(generateUniqueLink())}
                            title="Перегенерировать"
                        ></i>
                    </div>
                    {validationErrors?.uniqueLink && (
                        <span className="form-input__error-message">
                            {validationErrors.uniqueLink}
                        </span>
                    )}
                </div>

                <div className="create-edit-master__form-group">
                    <label>Заводы:</label>
                    <Select
                        isMulti
                        value={selectedFactories}
                        options={availableFactories}
                        onChange={setSelectedFactories}
                        placeholder="Выберите заводы"
                    />
                </div>

                <div className="create-edit-master__modal-actions">
                    <button onClick={handleSave} className="create-edit-master__btn-save">Сохранить</button>
                    <button onClick={onClose} className="create-edit-master__btn-cancel">Отменить</button>
                </div>
            </div>

            {toastMessage && (
                            <Toast
                                message={toastMessage}
                                type={toastType}
                                onClose={() => setToastMessage(null)}
                            />
                        )}

        </div>
    );
};

export default CreateOrEditMasterModal;
