import React, { useRef } from 'react';
import * as XLSX from 'xlsx';

const ExcelParser = ({ onParsedData, selectedFactory, selectedMonth, onUploadNotAllowed, onError }) => {
    const fileInputRef = useRef(null);

    const handleButtonClick = () => {
        if (!selectedFactory || !selectedMonth) {
            onUploadNotAllowed();
        } else {
            fileInputRef.current.click();
        }
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            parseFile(file);
        }

        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const parseFile = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            try {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                let allParsedData = [];

                // Пробегаем по всем вкладкам (листам)
                workbook.SheetNames.forEach(sheetName => {
                    const sheet = workbook.Sheets[sheetName];
                    const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                    const parsedSheetData = findTimesheetTable(parsedData); // Парсим данные с текущего листа
                    allParsedData = allParsedData.concat(parsedSheetData); // Добавляем данные в итоговый массив
                });

                onParsedData({ data: allParsedData });
            } catch (error) {
                // Передаем ошибку парсинга
                onError('Ошибка при чтении файла. Убедитесь, что файл является корректным Excel-документом.');
                console.error(error);
            }
        };
        reader.onerror = () => {
            // Обработка ошибок чтения файла
            onError('Ошибка при загрузке файла. Пожалуйста, попробуйте снова.');
        };
        reader.readAsArrayBuffer(file);
    };

    const findTimesheetTable = (data) => {
        let startRow = -1;
        let startCol = -1;
        let fioCol = -1;
        let dateColumns = [];
        let result = [];

        // Поиск начальных строк и столбцов
        for (let i = 0; i < 6; i++) { // ограничиваемся первыми строками для поиска
            for (let j = 0; j < 12; j++) {

                if (!data[i] || !data[i][j]) break;

                const cellValue = data[i][j];
                if (cellValue && isWholeNumber(cellValue)) {
                    startRow = i;
                    startCol = j;
                    break;
                }
            }

            if (startRow !== -1 && startCol !== -1) {
                // Находим столбец с ФИО
                for (let i = startRow - 1; i < startRow + 1; i++) {
                    for (let j = 0; j < data[i].length; j++) {
                        if (data[i][j] && normalizeString(data[i][j]).startsWith("ФИО")) {
                            fioCol = j;
                            break;
                        }
                    }
                    if (fioCol >= 0) break;
                }
                break;
            }
        }

        if (startRow === -1 || startCol === -1 || fioCol === -1) {
            onError("Не удалось найти начало таблицы с днями или столбец 'ФИО'.");
            return result;
        }

        // Формируем список столбцов для дней
        for (let j = startCol; j < data[startRow].length; j++) {
            if (isWholeNumber(data[startRow][j])) {
                dateColumns.push(j);
            }
        }

        // Парсим табель по мастерам, исключая заголовки и агрегированные строки
        return parseTimesheetData(data, startRow, dateColumns, fioCol);
    };

    const parseTimesheetData = (data, startRow, dateColumns, fioCol) => {
        const result = [];
        let currentEmployee = null;

        for (let i = startRow + 1; i < data.length; i++) {
            const nameCell = data[i][fioCol];

            // Если ФИО есть, создаем новую запись для текущего сотрудника
            if (nameCell && typeof nameCell === "string" && nameCell.trim() !== "") {
                currentEmployee = {
                    name: nameCell.trim(),
                    hours: new Array(dateColumns.length).fill(0)
                };
                result.push(currentEmployee);
            }

            // Если строка без ФИО, но данные о сотруднике уже есть (предыдущая строка была с ФИО)
            if (currentEmployee) {
                for (let j = 0; j < dateColumns.length; j++) {
                    const columnIndex = dateColumns[j];
                    const cellValue = data[i][columnIndex];
                    const hours = cellValue !== undefined && cellValue !== null && !isNaN(cellValue) ? parseFloat(cellValue) : 0;
                    currentEmployee.hours[j] += hours;
                }
            }
        }

        return result;
    };

    const isWholeNumber = (value) => {
        const num = Number(value);
        return Number.isInteger(num) && num > 0 && num <= 31;
    };


    const normalizeString = (str) => {
        return String(str).replace(/[^\wа-яА-ЯёЁ\s]/g, "").replace(/\s+/g, "").toUpperCase();
    };

    return (
        <div>
                    <button onClick={handleButtonClick} className="upload-button">
                        Выбрать файл
                    </button>
                    <input
                        type="file"
                        accept=".xlsx"
                        onChange={handleFileUpload}
                        ref={fileInputRef}
                        className="excel-upload-input"
                        style={{ display: 'none' }}
                    />
                </div>
    );
};

export default ExcelParser;
